import React, { useEffect, useState } from "react";
import { useApiGet } from "../../../api/apiCall";
import {
	// getAllApplicationTypesUnpaginatedUrl,
	getRecentNoticessUrl
} from "../../../api/urls";
import { ApplicationTabs, GlobalMenu, MessageBox } from "../../../ui_elements";
import { Jupeb, LandingModal, Sandwich, Undergraduate } from "./containers";
import DOMPurify from "dompurify";
import Hero from "../../../assets/images/landing_image.png";

import styles from "./styles.module.css";
import {
	HomeBookIcon,
	HomeBubbleIcon,
	HomeShapeIcon,
	WelecomeCircle
} from "../../../assets/svgs";
import { APPLICATION_ID, APPLICATION_MODE } from "../../../utils/constants";
import { ArrowUp } from "../../../assets/svgs";
import { Diploma } from "./containers/diploma";
const date = new Date();

const Home = () => {
	const [message, setMessage] = useState(true);
	// const [currentApplication, setCurrentApplication] = useState("");
	const [modal, setModal] = useState(false);
	const handleCurrentLink = (linkObject) => {
		if (
			linkObject?.applicationMode === APPLICATION_MODE.UTME ||
			linkObject?.applicationMode === APPLICATION_MODE.SUPPLEMENTARY ||
			linkObject?.applicationMode === APPLICATION_MODE.DIRECT_ENTRY
		) {
			return {
				pathname: `/generate_jamb_students_invoice`,
				state: { application: linkObject }
			};
		} else if (
			linkObject?.applicationMode === APPLICATION_MODE.PG ||
			linkObject?.applicationMode ===
				APPLICATION_MODE.FOUR_YEAR_SANDWICH ||
			linkObject?.applicationMode === APPLICATION_MODE.FIVE_YEAR_SANDWICH
		) {
			return {
				pathname: `/pg_students_invoice`,
				state: { application: linkObject }
			};
		} else if (
			linkObject?.applicationMode ===
			APPLICATION_MODE.INTER_UNIVERSITY_TRANSFER
		) {
			return {
				pathname: `/generate_inter_university_invoice`,
				state: { application: linkObject }
			};
		} else if (linkObject?.applicationMode === APPLICATION_MODE.CCE) {
			return {
				pathname: `/generate_cce_invoice`,
				state: { application: linkObject }
			};
		} else if (
			linkObject?.applicationMode === APPLICATION_MODE.STAFF_REQUEST
		) {
			return {
				pathname: `/generate_staff_request_invoice`,
				state: { application: linkObject }
			};
		} else if (linkObject?.applicationMode === APPLICATION_MODE.JUPEB) {
			return {
				pathname: `/generate_application_invoice`,
				state: { application: linkObject }
			};
		} else if (linkObject?.applicationMode === APPLICATION_MODE.DIPLOMA) {
			return {
				pathname: `/generate_application_invoice`,
				state: { application: linkObject }
			};
		} else {
			console.log("Not a valid application mode");
		}
	};

	const applicationForms = {
		pg: {
			applicationMode: APPLICATION_MODE.PG,
			id: APPLICATION_ID.PG,
			name: "Postgraduate Application"
		},
		JUPEB: {
			applicationMode: APPLICATION_MODE.JUPEB,
			id: APPLICATION_ID.JUPEB,
			name: "JUPEB"
		},
		DIPLOMA: {
			applicationMode: APPLICATION_MODE.DIPLOMA,
			id: APPLICATION_ID.DIPLOMA,
			name: "DIPLOMA"
		},
		UTME: {
			applicationMode: APPLICATION_MODE.UTME,
			id: APPLICATION_ID.UTME,
			name: "Post UTME"
		},
		directEntry: {
			applicationMode: APPLICATION_MODE.DIRECT_ENTRY,
			id: APPLICATION_ID.DIRECT_ENTRY,
			name: "Direct Entry"
		},
		supplementary: {
			applicationMode: APPLICATION_MODE.SUPPLEMENTARY,
			id: APPLICATION_ID.SUPPLEMENTARY,
			name: "Admission Shopping"
		},
		staffRequestForm: {
			applicationMode: APPLICATION_MODE.STAFF_REQUEST,
			id: APPLICATION_ID.STAFF_REQUEST,
			name: "Staff Request"
		},
		fourYearSandwich: {
			applicationMode: APPLICATION_MODE.FOUR_YEAR_SANDWICH,
			id: APPLICATION_ID.FOUR_YEAR_SANDWICH,
			name: "Four Year Sandwich"
		},
		fiveYearSandwich: {
			applicationMode: APPLICATION_MODE.FIVE_YEAR_SANDWICH,
			id: APPLICATION_ID.FIVE_YEAR_SANDWICH,
			name: "Five Year Sandwich"
		},
		interUniversityTransfer: {
			applicationMode: APPLICATION_MODE.INTER_UNIVERSITY_TRANSFER,
			id: APPLICATION_ID.INTER_UNIVERSITY_TRANSFER,
			name: "Inter University Transfer"
		}
	};
	const { data, isFetched } = useApiGet(getRecentNoticessUrl(), {
		keepPreviousData: true
	});

	useEffect(() => {
		if (isFetched) {
			setModal(true);
		}
	}, [isFetched]);

	const handleScroll = () => {
		window.scrollTo(0, document.body.scrollHeight);
	};

	const applicationTabsInfo = [
		{
			title: "Undergraduate",
			body: (
				<Undergraduate
					handleCurrentLink={handleCurrentLink}
					applicationForms={applicationForms}
				/>
			)
		},
		{
			title: "JUPEB",
			body: (
				<Jupeb
					handleCurrentLink={handleCurrentLink}
					applicationForms={applicationForms}
				/>
			)
		},
		{
			title: "Sandwich Programmes",
			body: (
				<Sandwich
					handleCurrentLink={handleCurrentLink}
					applicationForms={applicationForms}
				/>
			)
		},
		{
			title: "Diploma",
			body: (
				<Diploma
					handleCurrentLink={handleCurrentLink}
					applicationForms={applicationForms}
				/>
			)
		}
	];
	// const {
	// 	data: applications,
	// 	// isLoading,
	// 	// error
	// } = useApiGet(getAllApplicationTypesUnpaginatedUrl());

	// const random = (array) => {
	// 	if (array?.length > 0) {
	// 		const arrayIndex = Math.floor(Math.random(3.32) * array.length);
	// 		return array[arrayIndex];
	// 	}
	// };

	// useEffect(() => {
	// 	setCurrentApplication(
	// 		random(applications?.data?.filter((item) => item.active))
	// 	);
	// }, [applications?.data]);

	// if (isLoading) return <PageLoader />;
	// if (error)
	// 	return "An error has occurred: " + error?.response?.data?.message;

	return (
		<>
			{!data?.data || data?.data.length === 0 ? null : (
				<LandingModal
					data={data?.data}
					isOpen={modal}
					closeModal={() => setModal(false)}
				/>
			)}
			{!data?.data ||
			data?.data?.length === 0 ||
			message === false ? null : (
				<MessageBox
					openModal={() => setModal(true)}
					closeMessage={setMessage}
					title={data?.data[0]?.title}
					message={
						<div
							className={`d-flex align-items-baseline ${styles.message_body}`}
						>
							<div
								dangerouslySetInnerHTML={{
									__html: DOMPurify.sanitize(
										data?.data[0]?.description.slice(0, 70)
									)
								}}
							/>
							<span>....</span>
						</div>
					}
				/>
			)}
			<GlobalMenu isLanding={"unAuthenticated"} />
			<main className={`${styles.container}`}>
				<div className={styles.firstCover}>
					<div className={styles.sectionsContainer}>
						<section className="d-flex justify-content-center w-100 justify-content-md-between align-items-center">
							<div>
								{/* {currentApplication ? (
							<div className={styles.notice}>
								<span>Notice</span>
								{`${currentApplication.name} is now open!`}
							</div>
						) : (
							<></>
						)} */}
								<h1>
									Welcome to University
									<br /> of Nigeria Portal
								</h1>
								<p className="mb-4">
									Step through the virtual doors of our Portal
									- Your Gateway to Knowledge, Excellence, and
									Limitless Possibilities!
								</p>
								<button
									onClick={handleScroll}
									className={styles.btn}
								>
									Learn more
								</button>
							</div>
							<div className={styles.landing_sect}>
								<HomeBubbleIcon />
								<HomeBookIcon />
								<HomeShapeIcon />
								<WelecomeCircle
									className={styles.welcome_circle}
								/>
								<img src={Hero} alt="landing_img" />
							</div>
						</section>
					</div>
				</div>
			</main>
			<div className={styles.application_section_container}>
				<div className={`mb-5 ${styles.application_section_header}`}>
					<h2 className="pb-1">Our Programmes</h2>
					<p>
						Explore a World of Learning Possibilities: Discover Our
						Diverse Academic Pathways
					</p>
				</div>
				<ApplicationTabs items={applicationTabsInfo} />
			</div>
			<footer
				className={`d-flex flex-column align-items-center justify-content-center mt-5 ${styles.home_footer}`}
			>
				
				<p className={`${styles.call_centre_number}`}>Need help?  <span>-</span> <span>08139833300</span></p>
				<p>
					{`Copyright©️ ${date.getFullYear()} All rights reserved by University of
					Nigeria Nsukka. Powered by Tenece`}
				</p>
				<div
					className={`${styles.fab} d-flex align-items-center justify-content-center`}
					onClick={() =>
						window.scrollTo({
							top: 0,
							behavior: "smooth"
						})
					}
				>
					<ArrowUp />
				</div>
			</footer>
		</>
	);
};

export default Home;
